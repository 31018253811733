import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { isLinkExternal } from 'utils/helpers';
import useScreenRecognition from 'hooks/useScreenRecognition';
import ConnectBlock from 'components/ConnectBlock';
import getParsedConnectBlockMediaLinks from 'components/ConnectBlock/parsers';
import 'components/Header/HeaderNavigation/HeaderNavigation.scss';
import { IHeaderCustomNavigationProps } from './model';

const activeLinkClassName = 'active';

const HeaderCustomNavigation: FC<IHeaderCustomNavigationProps> = ({
  navItems,
  mediaLinks,
  hideSocialMediaBlock,
  ariaLabel,
}) => {
  const { isTablet, isMobile } = useScreenRecognition();
  const unwrappedMediaLinks = getParsedConnectBlockMediaLinks(mediaLinks);
  const scrollCallback = useCallback((anchor) => () => scrollTo(anchor), []);

  return (
    <div className="header-navigation-frame">
      <nav className="header-navigation" aria-label={ariaLabel}>
        {navItems.map(({ properties: { simpleLink } }) => {
          const { target, name, url, queryString } = simpleLink?.[0] || {};

          if (queryString) {
            return (
              <button
                type="button"
                className="nav-link"
                key={name}
                onClick={scrollCallback(queryString)}
              >
                <span className="nav-link-title">{name}</span>
              </button>
            );
          }

          if (isLinkExternal(url, target)) {
            return (
              <a href={url} key={name} className="nav-link">
                <span className="nav-link-title">{name}</span>
              </a>
            );
          }

          return (
            <Link
              partiallyActive
              activeClassName={activeLinkClassName}
              key={url}
              to={url || '/'}
              className="nav-link"
            >
              <span className="nav-link-title">{name}</span>
            </Link>
          );
        })}
      </nav>
      {(isTablet || isMobile) && hideSocialMediaBlock === '0' && unwrappedMediaLinks?.length ? (
        <ConnectBlock links={unwrappedMediaLinks} />
      ) : null}
    </div>
  );
};

export default HeaderCustomNavigation;
