import React, { FC, useCallback } from 'react';

import IconCustom from 'components/common/IconCustom';

import { CountrySelectorProps } from './model';

import './CountrySelector.scss';

const allowedKeywords = ['Enter', 'Shift', ' '];
const allowedKeys = [13, 16, 32];

const CountrySelector: FC<CountrySelectorProps> = ({
  selectedCountry,
  toggleSelector,
  countrySelectorTriggerAriaLabel,
}) => {
  const toggleSelectorByKey = useCallback(
    (e) => {
      const isAllowedKey = allowedKeys.includes(e.keyCode) || allowedKeywords.includes(e.key);
      isAllowedKey && toggleSelector();
    },
    [toggleSelector]
  );

  return (
    <div className="nf-region-selector">
      <button
        type="button"
        className="nf-region-selector__btn"
        {...(countrySelectorTriggerAriaLabel && {
          'aria-label': `${countrySelectorTriggerAriaLabel} ${selectedCountry.name}`,
        })}
        onKeyUp={toggleSelectorByKey}
        onMouseDown={toggleSelector}
      >
        {selectedCountry.name}
        <IconCustom icon="arrow-1" />
      </button>
    </div>
  );
};

export default CountrySelector;
