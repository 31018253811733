export const getDateDiffInSeconds = (dateInMiliseconds1, dateInMiliseconds2): number => {
  const diffInSeconds = Math.abs(dateInMiliseconds1 - dateInMiliseconds2) / 1000;

  return Math.floor(diffInSeconds);
};

/**
 * @param timeString has a pattern "[number] 'minute(s)' | 'hour(s)' | 'day(s)' | 'week(s)'"
 */
export const getTimeAmountInSeconds = (timeString: string): number => {
  if (!timeString) return 0;

  const [amount, period] = timeString.split(' ');
  if (parseInt(amount, 10) === 0 || !period) {
    return 0;
  }

  const isPluralPeriod = period.lastIndexOf('s');
  const singularPeriod = isPluralPeriod ? period.slice(0, period.lastIndexOf('s')) : period;
  const numericTimeAmount = parseInt(amount, 10);
  let secondsAmount = 0;

  switch (singularPeriod) {
    case 'minute':
      secondsAmount = numericTimeAmount * 60;
      break;
    case 'hour':
      secondsAmount = numericTimeAmount * 60 * 60;
      break;
    case 'day':
      secondsAmount = numericTimeAmount * 60 * 60 * 24;
      break;
    case 'week':
      secondsAmount = numericTimeAmount * 60 * 60 * 24 * 7;
      break;
    default:
      break;
  }

  return secondsAmount;
};
