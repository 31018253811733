import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col, { ColProps } from 'react-bootstrap/Col';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import './SimpleSection.scss';
import { IPropsNFButton } from 'components/common/NFButton/model';
import NFButton from 'components/common/NFButton';

export interface ISimpleSectionComponentProps extends PodcastsPageTypes.ISimpleBlock {
  sectionCTA?: IPropsNFButton;
  sectionClassname?: string;
  sectionAriaLabel?: string;
  children?: React.ReactNode;
  columns?: ColProps;
}

const SimpleSection: FC<ISimpleSectionComponentProps> = ({
  sectionTitle,
  sectionSubtitle,
  sectionBgColor,
  isSectionRounded,
  sectionCTA,
  sectionClassname = '',
  sectionAriaLabel,
  children,
  columns,
}) => {
  return (
    <div
      className={`simple-section ${sectionClassname} ${
        isSectionRounded ? 'simple-section--rounded' : ''
      }`}
      style={
        sectionBgColor
          ? ({
              backgroundColor: `var(--${sectionBgColor?.[0]?.properties?.colorPicker?.label})`,
            } as React.CSSProperties)
          : undefined
      }
      {...(sectionAriaLabel ? { 'aria-label': sectionAriaLabel } : {})}
    >
      <Container>
        <Row>
          <Col sm={12} md={columns?.md || 12} lg={columns?.lg || { span: 10, offset: 1 }}>
            {sectionTitle ? (
              <DangerouslySetInnerHtml className="simple-section__title" html={sectionTitle} />
            ) : null}
            {sectionSubtitle ? (
              <DangerouslySetInnerHtml
                className="simple-section__subtitle"
                html={sectionSubtitle}
              />
            ) : null}

            {children || null}
          </Col>
        </Row>
      </Container>

      {sectionCTA ? (
        <div className="simple-section__cta-button-wrapper">
          <NFButton {...sectionCTA} />
        </div>
      ) : null}
    </div>
  );
};

export default SimpleSection;
