import { isBrowser } from 'utils/browser';

const isItemOrItsChildActive = (
  id: string | null,
  searchChildrenClassName: string,
  activeLinkClassName: string
): boolean => {
  if (!id || !isBrowser()) {
    return false;
  }

  const parent = document.getElementById(id);
  if (!parent) {
    return false;
  }

  // check whether item is a clickable link itself
  if (parent.querySelector('.active')) {
    return true;
  }

  const children = parent.getElementsByClassName(searchChildrenClassName);

  if (!children || !children.length) {
    return false;
  }
  const childrenArray = Array.prototype.slice.call(children);

  return childrenArray.some((item) => item.classList.contains(activeLinkClassName));
};

const Helpers = {
  isItemOrItsChildActive,
};

export default Helpers;
