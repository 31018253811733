import { ConnectSingleLink } from './model';

const getParsedConnectBlockMediaLinks = (
  mediaLinks?: UmbracoTypes.IMediaLink[]
): ConnectSingleLink[] => {
  return mediaLinks?.length
    ? mediaLinks.map(
        ({ properties: { mediaLink, mediaLinkIcon, activeLinkColor, linkColor, mediaTitle } }) => ({
          mediaLinkIcon,
          mediaLink,
          activeColor: activeLinkColor?.[0]?.properties?.colorPicker?.label || 'navy',
          color: linkColor?.[0]?.properties?.colorPicker?.label || 'navy',
          mediaTitle,
        })
      )
    : [];
};

export default getParsedConnectBlockMediaLinks;
