import { RefObject, useEffect } from 'react';
import { isBrowserScrollbar } from 'utils/browser';

const useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  callBack: () => void,
  exceptionsIds?: string[]
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | any) => {
      const isClickableElementInsideExceptionIdElement = exceptionsIds?.some((exceptionId) =>
        event.target.closest(`#${exceptionId}`)
      );

      if (isClickableElementInsideExceptionIdElement || isBrowserScrollbar(event)) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callBack]);
};

export default useClickOutside;
