import { useState, useLayoutEffect } from 'react';
import throttle from 'lodash.throttle';

import {
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  LARGE_TABLET_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
} from 'utils/constants';
import { ScreenRecognitionInitialState, UseScreenRecognitionHook } from './model';

const useScreenRecognition: UseScreenRecognitionHook = (
  initialState?: ScreenRecognitionInitialState
) => {
  const [isMobile, setIsMobile] = useState<null | boolean>(initialState?.isMobile || null);
  const [isTablet, setIsTablet] = useState<null | boolean>(initialState?.isTablet || null);
  const [isMiddleTablet, setIsMiddleTablet] = useState<null | boolean>(
    initialState?.isMiddleTablet || null
  );
  const [isDesktop, setIsDesktop] = useState<null | boolean>(initialState?.isDesktop || null);
  const [isLargeDesktop, setIsLargeDesktop] = useState<null | boolean>(
    initialState?.isLargeDesktop || null
  );
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      const width = document.body.clientWidth;
      const height = window.innerHeight;

      setWindowWidth(width);
      setWindowHeight(height);
      setIsMobile(width < TABLET_BREAKPOINT);
      setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
      setIsMiddleTablet(width >= TABLET_BREAKPOINT && width < LARGE_TABLET_BREAKPOINT);
      setIsDesktop(width >= DESKTOP_BREAKPOINT && width < LARGE_DESKTOP_BREAKPOINT);
      setIsLargeDesktop(width >= LARGE_DESKTOP_BREAKPOINT);
    };

    handleResize();

    const throttledOnResize = throttle(handleResize, 600);
    window.addEventListener('resize', throttledOnResize);

    return () => {
      throttledOnResize.cancel();
      window.removeEventListener('resize', throttledOnResize);
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isMiddleTablet,
    isDesktop,
    isLargeDesktop,
    windowWidth,
    windowHeight,
  };
};

export default useScreenRecognition;
