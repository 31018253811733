import React, { FC } from 'react';

import { IIconCustom } from './models';
import './IconCustom.scss';

const IconCustom: FC<IIconCustom> = ({ icon, style, onClick }) => (
  <span
    className={`nf-icon-custom icon-${icon}`}
    style={style}
    onClick={onClick}
    aria-hidden="true"
  />
);

export default IconCustom;
