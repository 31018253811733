import React, { FC } from 'react';

import { IPropsImageUsual } from './model';

const ImageUsual: FC<IPropsImageUsual> = ({
  src,
  imageAlt,
  imageClassName,
  imageStyle,
}): React.ReactElement | null =>
  src ? (
    <img
      className={`${imageClassName || ''}`}
      src={src}
      alt={imageAlt || ''}
      style={imageStyle ? { ...imageStyle } : {}}
    />
  ) : null;

export default ImageUsual;
