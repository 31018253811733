export const NFButtonDefaultData: NFButton.INFBtnParsedData = {
  ariaLabel: '',
  btnText: '',
  link: '',
  gatsbyDocFilePath: '',
  btnStyles: {
    inactiveBgColor: null,
    inactiveBorderColor: null,
    inactiveTextColor: null,
    activeBgColor: null,
    activeBorderColor: null,
    activeTextColor: null,
    disabledBgColor: null,
    disabledBorderColor: null,
    disabledTextColor: null,
  },
};

export const parseNFButton = (
  param: NFButton.IUmbracoNFBtnMainProperties
): NFButton.INFBtnParsedData => {
  const result: NFButton.INFBtnParsedData = {
    ariaLabel: param.ariaLabel || NFButtonDefaultData.ariaLabel,
    btnText: param.text || NFButtonDefaultData.btnText,
    link: param?.link?.[0]?.url
      ? `${param?.link?.[0]?.url}${param?.link?.[0]?.queryString || ''}`
      : NFButtonDefaultData.link,
    target: param?.link?.[0]?.target,
    gatsbyDocFilePath: param?.gatsbyDocFile?.relativePath || NFButtonDefaultData.gatsbyDocFilePath,
    btnStyles: {
      inactiveBgColor:
        param.btnStyles?.[0]?.properties.inactiveBgColor?.[0]?.properties.colorPicker?.label ||
        null,
      inactiveBorderColor:
        param.btnStyles?.[0]?.properties.inactiveBorderColor?.[0]?.properties.colorPicker?.label ||
        null,
      inactiveTextColor:
        param.btnStyles?.[0]?.properties.inactiveTextColor?.[0]?.properties.colorPicker?.label ||
        null,
      activeBgColor:
        param.btnStyles?.[0]?.properties.activeBgColor?.[0]?.properties.colorPicker?.label || null,
      activeBorderColor:
        param.btnStyles?.[0]?.properties.activeBorderColor?.[0]?.properties.colorPicker?.label ||
        null,
      activeTextColor:
        param.btnStyles?.[0]?.properties.activeTextColor?.[0]?.properties.colorPicker?.label ||
        null,
      disabledBgColor:
        param.btnStyles?.[0]?.properties.disabledBgColor?.[0]?.properties.colorPicker?.label ||
        null,
      disabledBorderColor:
        param.btnStyles?.[0]?.properties.disabledBorderColor?.[0]?.properties.colorPicker?.label ||
        null,
      disabledTextColor:
        param.btnStyles?.[0]?.properties.disabledTextColor?.[0]?.properties.colorPicker?.label ||
        null,
    },
  };

  return result;
};
