import React, { FC, ReactElement } from 'react';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import './HeaderLogo.scss';
import { IPropsHeaderLogo } from './model';

const HeaderLogo: FC<IPropsHeaderLogo> = (props): ReactElement | null => {
  const { logo, headerLogoAriaLabel, headerLogoLink } = props;

  return logo.svg?.content ? (
    <Link to={headerLogoLink} className="nf-header-logo" aria-label={headerLogoAriaLabel}>
      <DangerouslySetInnerHtml html={logo.svg?.content} />
    </Link>
  ) : null;
};

export default HeaderLogo;
