import React, { FC } from 'react';

import { ContactBlockProps } from './model';
import './ContactBlock.scss';

const ContactBlock: FC<ContactBlockProps> = ({ contactInfo, selectorOpen }) => {
  const { title, contacts } = contactInfo;

  return (
    <div className={`nf-footer-contact ${selectorOpen ? 'state--cropped' : 'opp'}`}>
      {title?.trim().length ? (
        <div className="nf-footer-contact__title" dangerouslySetInnerHTML={{ __html: title }} />
      ) : null}
      {contacts?.length
        ? contacts.map(
            ({
              properties: {
                contactTitle,
                contactPhone,
                contactText,
                contactLinkAriaLabel,
                switchOffLink,
              },
            }) => {
              return (
                <div className="nf-footer-contact__tel" key={`${contactPhone}${contactText}`}>
                  {contactTitle?.trim().length ? (
                    <p className="nf-footer-contact__tel-title">{contactTitle}</p>
                  ) : null}
                  {contactText?.trim().length ? (
                    <span className="nf-footer-contact__tel-text">{contactText}</span>
                  ) : null}
                  {contactPhone?.trim().length && !switchOffLink ? (
                    <a
                      href={`tel:${contactPhone}`}
                      className="nf-footer-contact__tel-link"
                      aria-label={contactLinkAriaLabel}
                    >
                      {contactPhone}
                    </a>
                  ) : null}
                  {contactPhone?.trim().length && switchOffLink ? (
                    <div className="nf-footer-contact__tel-link">{contactPhone}</div>
                  ) : null}
                </div>
              );
            }
          )
        : null}
    </div>
  );
};
export default ContactBlock;
