import React, { FC, useState, useCallback, useRef } from 'react';
import classNames from 'classnames';

import LanguageItem from 'components/LanguageSelector/LanguageItem';
import IconCustom from 'components/common/IconCustom';
import useClickOutside from 'hooks/useClickOutside';

import { ILanguageSelectorComponentProps } from 'components/LanguageSelector/model';
import 'components/LanguageSelector/LanguageSelector.scss';

const LanguageSelector: FC<ILanguageSelectorComponentProps> = ({ title, opener, languages }) => {
  const [toggleClass, setToggleClass] = useState(false);
  const langSelectorRef = useRef(null);

  function handleDropdownOpen() {
    setToggleClass(!toggleClass);
  }

  const onCloseLangSelector = useCallback(() => {
    if (!toggleClass) return;
    setToggleClass(false);
  }, [toggleClass]);

  useClickOutside(langSelectorRef, onCloseLangSelector);

  const classes = classNames('language-selector', {
    'visible-drop': toggleClass,
  });

  return (
    <div className="language-selector-holder">
      <span className="divider">|</span>
      <div className={classes}>
        <strong className="language-selector__title">{title}</strong>
        <div className="language-selector__drop">
          <button
            type="button"
            className="language-selector__opener"
            onMouseDown={handleDropdownOpen}
          >
            <span className="language-selector__opener-mob">{opener.mob}</span>
            <span className="language-selector__opener-desktop">{opener.desktop}</span>
            <IconCustom icon="arrow-1" />
          </button>

          <div className="language-selector__menu">
            <ul className="language-selector__list" ref={langSelectorRef}>
              {languages?.map((item) => (
                <LanguageItem key={item.langCode} {...item} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
