import React, { FC, ReactElement } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import './SocialRedirection.scss';
import { IPropsSocialRedirection } from './model';

const SocialRedirection: FC<IPropsSocialRedirection> = ({
  links,
  handleOpenModal,
}): ReactElement => {
  return (
    <div data-test="SocialRedirection" className="social-redirection">
      {links?.length &&
        links.map(({ mediaLink, mediaLinkIcon, color, activeColor, mediaTitle }) => (
          <button
            key={mediaTitle}
            type="button"
            aria-label={mediaTitle}
            title={mediaTitle}
            className={`social-trigger ${color}-default-text ${activeColor}-active-text`}
            onClick={handleOpenModal(mediaLink)}
          >
            <DangerouslySetInnerHtml html={mediaLinkIcon?.svg?.content} />
          </button>
        ))}
    </div>
  );
};

export const query = graphql`
  fragment FragmentSocialRedirection on TSocialRedirectionBlockProperties {
    properties {
      socialRedirectionLinks {
        ...FragmentConnectBlock
      }
      socialRedirectionModals {
        ...FragmentRedirectionModal
      }
    }
  }
`;

export default SocialRedirection;
