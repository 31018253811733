import React, { FC, ReactElement } from 'react';

import './HeaderSearchToggler.scss';

interface IPropsHeaderNavigation {
  handleSearchFieldVisibility: () => void;
}

const HeaderSearchToggler: FC<IPropsHeaderNavigation> = (props): ReactElement => {
  const { handleSearchFieldVisibility } = props;

  return (
    <div className="hf-header-search-toggler">
      <button
        id="header-search-toggler"
        type="button"
        aria-label="search"
        className="toggler icon-search_icon-1"
        onClick={handleSearchFieldVisibility}
      />
    </div>
  );
};

export default HeaderSearchToggler;
