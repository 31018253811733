import React, { CSSProperties, FC, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import Container from 'react-bootstrap/Container';

import useLocalStorage from 'hooks/useLocalStorage';
import useOnDetectOrientation from 'hooks/useOnDetectOrientation';
import ELEMENTS, { COVID_BANNER_TYPE, COVID_BANNER_POSITION } from 'utils/constants';
import { isBrowser } from 'utils/browser';

import UniversalImage from 'components/common/UniversalImage';
import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { getDateDiffInSeconds, getTimeAmountInSeconds } from './utils/helpers';

import 'components/CovidBanner/CovidBanner.scss';

const CovidBanner: FC<CovidBannerTypes.ICovidBannerComponentProps> = ({
  ariaLabel,
  type,
  position,
  htmlContent,
  interval,
  callbackCloseBanner,
  closeButtonText,
  isHideCloseButton,
  bannerHeightInPercentage,
  desktopFullWidthImage,
  mobileFullWidthImage,
  isMobile,
}) => {
  const { isPortraitOrientation: isOrientationChanged } = useOnDetectOrientation();

  const bannerRef = useRef<HTMLDivElement>(null);
  const [bannerInlineStyles, setBannerInlineStyles] = useState<CSSProperties>({});
  const [storedInterval, setStoredInterval] = useLocalStorage('interval');
  const [storedLastRenderTime, setStoredLastRenderTime] = useLocalStorage('covidLastRenderTime');
  const [isShownBanner, setIsShownBanner] = useState<boolean | undefined>();
  const [isSessionNew, setIsSessionNew] = useState<boolean | undefined>(() => {
    if (!isBrowser()) return;
    if (!window.name) {
      window.name = 'withCovidBanner';

      return true;
    }

    return false;
  });

  // set up interval
  useEffect(() => {
    const intervalInSeconds = getTimeAmountInSeconds(interval);
    // put interval into storage initially
    if (!storedInterval) {
      setStoredInterval(intervalInSeconds);
    }
    // update interval in storage in case upcoming invernal differs from existing one
    if (storedInterval !== intervalInSeconds) {
      setStoredInterval(intervalInSeconds);
    }
  }, []);

  useEffect(() => {
    // handle case when interval = 0:
    if (storedInterval === 0) {
      if (isSessionNew) {
        setStoredLastRenderTime(null);
        setIsSessionNew(false);
        setIsShownBanner(true);

        return;
      }
      if (storedLastRenderTime) {
        return setIsShownBanner(false);
      }
      if (!storedLastRenderTime) {
        return setIsShownBanner(true);
      }
    }
    // handle case when interval contains a value
    if (storedInterval !== 0) {
      if (!storedLastRenderTime) {
        return setIsShownBanner(true);
      }
      if (getDateDiffInSeconds(storedLastRenderTime, Date.now()) > storedInterval) {
        return setIsShownBanner(true);
      }

      return setIsShownBanner(false);
    }
  }, [storedLastRenderTime]);

  useEffect(() => {
    callbackCloseBanner(isShownBanner);
  }, [isShownBanner]);

  const onCloseBanner = useCallback(() => {
    const currentDate = Date.now();
    setStoredLastRenderTime(currentDate);
  }, []);

  const getBannerClassname = () => {
    let bannerClassname: string = '';

    if (position === COVID_BANNER_POSITION.top && type === COVID_BANNER_TYPE.pinned) {
      bannerClassname = 'covid-banner__relative covid-banner__top';
    }
    if (position === COVID_BANNER_POSITION.top && type === COVID_BANNER_TYPE.sticky) {
      bannerClassname = 'covid-banner__fixed covid-banner__top';
    }

    if (position === COVID_BANNER_POSITION.bottom && type === COVID_BANNER_TYPE.pinned) {
      bannerClassname = 'covid-banner__static covid-banner__bottom';
    }
    if (position === COVID_BANNER_POSITION.bottom && type === COVID_BANNER_TYPE.sticky) {
      bannerClassname = 'covid-banner__fixed covid-banner__bottom';
    }

    return bannerClassname;
  };

  const closeButtonEl = () => {
    const closeBtn = (
      <div onClick={onCloseBanner} role="presentation" className="covid-banner__close">
        <IconCustom icon="close_icon-2" />
        <span className="title">{closeButtonText}</span>
      </div>
    );
    if (!isHideCloseButton) return closeBtn;
    if (isHideCloseButton === '0') return closeBtn;
    if (isHideCloseButton === '1') return null;
  };

  useEffect(() => {
    if (!isBrowser()) return;
    if (!bannerHeightInPercentage) return;
    if (!bannerRef?.current) return;

    if (Object.keys(bannerRef.current.style).includes('height')) {
      bannerRef.current.style.height = 'auto';
    }
    const defaultBannerHeight = Math.floor(bannerRef?.current?.clientHeight);
    const inPercentageBannerHeight = Math.floor(
      window.screen.height * (bannerHeightInPercentage / 100)
    );

    setBannerInlineStyles(
      defaultBannerHeight > inPercentageBannerHeight
        ? { height: 'auto' }
        : { height: `${inPercentageBannerHeight}px` }
    );
  }, [bannerRef, bannerHeightInPercentage, isOrientationChanged, isMobile]);

  const imageBanner = useMemo(() => {
    if (!mobileFullWidthImage || !desktopFullWidthImage) return null;

    return (
      <div className="covid-banner__image">
        {isMobile ? (
          <UniversalImage img={mobileFullWidthImage} objectFitData={{ objectFit: 'contain' }} />
        ) : (
          <UniversalImage img={desktopFullWidthImage} objectFitData={{ objectFit: 'contain' }} />
        )}
      </div>
    );
  }, [isMobile, mobileFullWidthImage, desktopFullWidthImage]);

  const textBanner = useMemo(
    () => (
      <Container fluid>
        <div className="covid-banner__content">
          <DangerouslySetInnerHtml html={htmlContent} />
        </div>
        {closeButtonEl()}
      </Container>
    ),
    [htmlContent]
  );

  const isImageBanner = useCallback(() => {
    return desktopFullWidthImage && mobileFullWidthImage;
  }, [desktopFullWidthImage, mobileFullWidthImage]);

  return isShownBanner ? (
    <section
      ref={bannerRef}
      id={ELEMENTS.COVID_BANNER_ID}
      aria-label={ariaLabel}
      className={`covid-banner ${getBannerClassname()}`}
      style={bannerInlineStyles}
    >
      <div className="covid-banner__stripe" />
      {isImageBanner() ? imageBanner : textBanner}
    </section>
  ) : null;
};

export default CovidBanner;
