import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';
import { IModalComponentProps } from './model';
import './Modal.scss';

const Modal = React.memo(
  ({
    children,
    isVisible,
    onClose,
    closeIconColor,
    className,
    closeBtnAriaLabel,
    closeBtnTitle,
    wrapperRef,
  }: IModalComponentProps) => {
    const [documentElement, setDocumentElement] = useState<HTMLElement>();
    useEffect(() => {
      setDocumentElement(document.body);
    }, []);

    const classes = classNames('modal-overlay', {
      [`${className}`]: className,
    });

    const modal = (
      <div className={classes}>
        <div className="modal-wrapper" {...(wrapperRef ? { ref: wrapperRef } : {})}>
          <button
            tabIndex={0}
            type="button"
            className="close-icon-wrapper"
            aria-label={closeBtnAriaLabel}
            onClick={onClose}
          >
            {closeBtnTitle ? <span className="close-btn-title">{closeBtnTitle}</span> : null}
            <IconCustom
              icon="close_icon-1"
              style={
                closeIconColor
                  ? ({
                      color: `var(--${closeIconColor})`,
                    } as React.CSSProperties)
                  : undefined
              }
            />
          </button>
          {children}
        </div>
      </div>
    );

    return isVisible && documentElement ? ReactDOM.createPortal(modal, documentElement) : null;
  }
);

export default Modal;
