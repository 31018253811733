import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { ILanguageItemComponentProps } from 'components/LanguageSelector/LanguageItem/model';
import 'components/LanguageSelector/LanguageItem/LanguageItem.scss';

const LanguageItem: FC<ILanguageItemComponentProps> = ({
  langTitle,
  langAriaLabel,
  link,
  active,
}) => {
  const classes = classNames('language-selector__item', { active });

  return (
    <li className={classes}>
      {active ? (
        <span>{langTitle}</span>
      ) : (
        <Link to={link} aria-label={langAriaLabel}>
          {langTitle}
        </Link>
      )}
    </li>
  );
};

export default LanguageItem;
