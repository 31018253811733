import React, { FC, useCallback } from 'react';

import ReactCountryFlag from 'react-country-flag';

import { CountrySelectorContentProps } from './model';

const CountrySelectorContent: FC<CountrySelectorContentProps> = ({
  selectCountry,
  countriesColumn,
}) => {
  const handleCountrySelect = useCallback(
    ({ name, countryCode, link }: FooterTypes.ICountry) => () => {
      selectCountry({ name, countryCode, link });
    },
    []
  );

  return (
    <>
      {countriesColumn &&
        countriesColumn.length > 0 &&
        countriesColumn.map(({ properties: { countriesBlocks } }) => {
          return (
            <div
              key={countriesBlocks[0].properties.title}
              className="nf-region-selector__country-block"
            >
              {countriesBlocks.map(({ properties: { title, countriesList } }) => (
                <div key={title}>
                  <p>{title}</p>
                  <ul className={`${countriesList.length > 7 ? 'grid-extra' : ''}`}>
                    {countriesList.map(({ properties: { name, countryCode, link } }) => (
                      <li
                        role="presentation"
                        key={countryCode}
                        onClick={handleCountrySelect({ name, countryCode, link })}
                      >
                        {link && (
                          <a title="redirect" href={link}>
                            {' '}
                          </a>
                        )}
                        <ReactCountryFlag
                          countryCode={countryCode}
                          style={{
                            width: '28px',
                            height: '28px',
                          }}
                          svg
                        />
                        <span
                          style={
                            name.split(' ').length <= 2
                              ? { whiteSpace: 'nowrap' }
                              : { width: '10ch' }
                          }
                        >
                          {name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          );
        })}
    </>
  );
};
export default CountrySelectorContent;
