import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { FooterBottomBannerProps } from './model';

import './FooterBottomBanner.scss';

const FooterBottomBanner: FC<FooterBottomBannerProps> = ({ footerBottomBanner }) => {
  return (
    <div className="nf-footer-bottom-banner">
      <DangerouslySetInnerHtml className="nf-footer-bottom-banner-text" html={footerBottomBanner} />
    </div>
  );
};

export default FooterBottomBanner;
