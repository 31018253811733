import React, { FC, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import { isLinkExternal } from 'utils/helpers';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPropsNFButton } from './model';
import './NFButton.scss';

const NFButton: FC<IPropsNFButton> = ({
  btnText,
  link,
  target,
  variant,
  size,
  btnStyles,
  type,
  ariaLabel,
  onClick,
  className,
  icon,
  customElement,
  id,
}) => {
  if (!btnText) {
    return null;
  }

  const inactiveBgColor = btnStyles?.inactiveBgColor || '';
  const inactiveBorderColor = btnStyles?.inactiveBorderColor || '';
  const inactiveTextColor = btnStyles?.inactiveTextColor || '';
  const activeBgColor = btnStyles?.activeBgColor || '';
  const activeBorderColor = btnStyles?.activeBorderColor || '';
  const activeTextColor = btnStyles?.activeTextColor || '';
  const disabledBgColor = btnStyles?.disabledBgColor || '';
  const disabledBorderColor = btnStyles?.disabledBorderColor || '';
  const disabledTextColor = btnStyles?.disabledTextColor || '';

  const activeBgColorApplied = activeBgColor ? ` ${activeBgColor}-active-bg` : '';
  const activeBorderColorApplied = activeBorderColor ? ` ${activeBorderColor}-active-border` : '';
  const activeTextColorApplied = activeTextColor ? ` ${activeTextColor}-active-text` : '';
  const inactiveBgColorApplied = inactiveBgColor ? ` ${inactiveBgColor}-default-bg` : '';
  const inactiveBorderColorApplied = inactiveBorderColor
    ? ` ${inactiveBorderColor}-default-border`
    : '';
  const inactiveTextColorApplied = inactiveTextColor ? ` ${inactiveTextColor}-default-text` : '';
  const disabledBgColorApplied = disabledBgColor ? ` ${disabledBgColor}-disabled-bg` : '';
  const disabledBorderColorApplied = disabledBorderColor
    ? ` ${disabledBorderColor}-disabled-border`
    : '';
  const disabledTextColorApplied = disabledTextColor ? ` ${disabledTextColor}-disabled-text` : '';

  const classes = classNames('nf-btn', {
    'nf-btn__primary': variant?.includes('primary'),
    'nf-btn__danger': variant?.includes('danger'),
    'nf-btn__light': variant?.includes('light'),
    'nf-btn__outline': variant?.includes('outline'),
    'nf-btn__shadow-outline': variant?.includes('shadowOutline'),
    'nf-btn__primary-hover-gray': variant?.includes('primaryHoverGray'),

    'nf-btn__lg': size?.includes('lg'),
    'nf-btn__sm': size?.includes('sm'),

    [activeBgColorApplied]: activeBgColorApplied,
    [activeBorderColorApplied]: activeBorderColorApplied,
    [activeTextColorApplied]: activeTextColorApplied,
    [inactiveBgColorApplied]: inactiveBgColorApplied,
    [inactiveBorderColorApplied]: inactiveBorderColorApplied,
    [inactiveTextColorApplied]: inactiveTextColorApplied,
    [disabledBgColorApplied]: disabledBgColorApplied,
    [disabledBorderColorApplied]: disabledBorderColorApplied,
    [disabledTextColorApplied]: disabledTextColorApplied,
    [className || '']: className,
  });

  const isExternal = useCallback(() => isLinkExternal(link, target), [link, target]);

  if (isExternal()) {
    return (
      <a className={classes} href={link} aria-label={ariaLabel} target={target}>
        <span>{btnText}</span>
      </a>
    );
  }

  if (link) {
    return (
      <Link className={classes} to={link} aria-label={ariaLabel}>
        <span>{btnText}</span>
      </Link>
    );
  }

  return (
    <button
      className={classes}
      onClick={onClick}
      type={type === 'submit' ? 'submit' : 'button'}
      aria-label={ariaLabel}
      {...(id ? { id } : {})}
    >
      {btnText}
      {icon?.svg?.content ? (
        <DangerouslySetInnerHtml element="span" html={icon.svg.content} className="nf-btn-icon" />
      ) : null}
      {customElement || null}
    </button>
  );
};

export const query = graphql`
  fragment FragmentNFButton on INFButton {
    ariaLabel
    text
    link {
      url
      queryString
      target
      name
    }
    gatsbyDocFile {
      relativePath
    }
    btnStyles {
      properties {
        activeBgColor {
          ...FragmentColorProps
        }
        activeBorderColor {
          ...FragmentColorProps
        }
        activeTextColor {
          ...FragmentColorProps
        }
        disabledBgColor {
          ...FragmentColorProps
        }
        disabledBorderColor {
          ...FragmentColorProps
        }
        disabledTextColor {
          ...FragmentColorProps
        }
        inactiveBgColor {
          ...FragmentColorProps
        }
        inactiveBorderColor {
          ...FragmentColorProps
        }
        inactiveTextColor {
          ...FragmentColorProps
        }
      }
    }
  }
`;

export default NFButton;
