import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import IconCustom from 'components/common/IconCustom';
import CountrySelectorContent from 'components/Footer/TopFooter/CountrySelector/CountrySelectorContent';
import getParsedConnectBlockMediaLinks from 'components/ConnectBlock/parsers';
import useClickOutside from 'hooks/useClickOutside';

import CountrySelector from './CountrySelector';
import ConnectBlock from '../../ConnectBlock';
import ContactBlock from './ContactBlock';

import { TopFooterProps } from './model';
import './TopFooter.scss';

const TopFooter: FC<TopFooterProps> = ({
  hideMediaLinks,
  hideContactInfo,
  hideCountrySelector,
  mediaLinksTitle,
  mediaLinks,
  contactInfo,
  countriesColumn,
  lang,
  handlerOpenRegionDropdown,
  setFooterHeight,
  countrySelectorTriggerAriaLabel,
}) => {
  const currentCountryCode = lang.split('-')[1];
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<FooterTypes.ICountry>({
    countryCode: '',
    name: '',
    link: '',
  });
  const countrySelectorRef = useRef<HTMLDivElement>(null);
  const topFooterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (countrySelectorRef.current && topFooterRef.current) {
      const paddingTop = 60;
      const paddingBottom = 30;
      const footerHeight =
        paddingTop +
        topFooterRef.current.offsetHeight +
        countrySelectorRef.current.offsetHeight +
        paddingBottom;

      setFooterHeight(`${footerHeight}px`);
    }
  }, [countrySelectorRef.current]);

  const selectCountry = useCallback(
    ({ name, countryCode, link }: FooterTypes.ICountry) => {
      setSelectedCountry({ name, countryCode, link });
      setSelectorOpen(false);
    },
    [selectedCountry]
  );

  const toggleSelector = useCallback(() => {
    handlerOpenRegionDropdown(!selectorOpen);
    setSelectorOpen(!selectorOpen);
  }, [selectorOpen]);

  const onCloseCountrySelector = useCallback(() => {
    if (!selectorOpen) return;
    handlerOpenRegionDropdown(false);
    setSelectorOpen(false);
  }, [selectorOpen]);

  useClickOutside(countrySelectorRef, onCloseCountrySelector);

  const handleOnBlur = useCallback((event: React.FocusEvent<HTMLUListElement | any>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setSelectorOpen(false);
    }
  }, []);

  const unwrappedMediaLinks = getParsedConnectBlockMediaLinks(mediaLinks);
  const hideMediaLinksBool = JSON.parse(hideContactInfo);
  const hideContactInfoBool = JSON.parse(hideMediaLinks);
  const hideCountrySelectorBool = JSON.parse(hideCountrySelector);

  useEffect(() => {
    countriesColumn.forEach(({ properties: { countriesBlocks } }) => {
      countriesBlocks.forEach(({ properties: { countriesList } }) => {
        countriesList.forEach(({ properties }) => {
          if (properties.countryCode === currentCountryCode.toLowerCase()) {
            setSelectedCountry(properties);
          }
        });
      });
    });
  }, []);

  return hideMediaLinksBool && hideContactInfoBool && hideCountrySelectorBool ? null : (
    <div className="nf-footer-top" ref={topFooterRef}>
      <Row>
        {!hideMediaLinksBool ? (
          <Col lg={4} md={12}>
            <ContactBlock selectorOpen={selectorOpen} contactInfo={contactInfo} />
          </Col>
        ) : null}

        {!hideContactInfoBool ? (
          <Col lg={3} md={4} sm={6}>
            <ConnectBlock title={mediaLinksTitle} links={unwrappedMediaLinks} />
          </Col>
        ) : null}

        {!hideCountrySelectorBool ? (
          <Col
            lg={{ span: 5, offset: 0 }}
            md={{ span: 5, offset: 3 }}
            sm={6}
            className="country-col"
          >
            <CountrySelector
              toggleSelector={toggleSelector}
              selectedCountry={selectedCountry}
              countrySelectorTriggerAriaLabel={countrySelectorTriggerAriaLabel}
            />

            {selectorOpen && (
              <div
                ref={countrySelectorRef}
                className="nf-region-selector__countries"
                onBlur={handleOnBlur}
              >
                <div
                  role="presentation"
                  className="nf-region-selector__close-btn"
                  onClick={toggleSelector}
                >
                  <IconCustom icon="close_icon-2" />
                  <span>Close</span>
                </div>
                <CountrySelectorContent
                  countriesColumn={countriesColumn}
                  selectCountry={selectCountry}
                />
              </div>
            )}
          </Col>
        ) : null}
      </Row>
    </div>
  );
};
export default TopFooter;
