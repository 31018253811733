import React, { FC, useCallback, useEffect, useRef, RefObject, useState } from 'react';
import dayjs from 'dayjs';

import { getCookie } from 'utils/helpers';
import { isBrowser } from 'utils/browser';
import useModal from 'hooks/useModal';
import useClickOutside from 'hooks/useClickOutside';

import NFButton from 'components/common/NFButton';
import Modal from 'components/Modal';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import 'components/PromoModal/PromoModal.scss';

const PROMO_COOKIE_NAME = 'promo-modal-cookie';
const PROMO_APPREAR_DELAY = 1000;

const PromoModal: FC<PromoModalTypes.IPromo> = ({
  promoModalTitle,
  promoModalDescription,
  promoModalBottomDisclaimer,
  promoModalCTAText,
  promoModalCountdown,
  promoModalImage,
  promoModalPdfName,
  promoModalPdfLink,
  promoModalImageAlt,
  promoModalCloseButtonAriaLabel,
}) => {
  const [documentElement, setDocumentElement] = useState<HTMLElement>();
  const modalRef: RefObject<HTMLDivElement> = useRef(null);
  const { isVisibleModal, openModal, closeModal } = useModal(false);

  useEffect(() => {
    setDocumentElement(document.body);
  }, []);

  useEffect(() => {
    let showModalTimer;
    if (!getCookie(PROMO_COOKIE_NAME)) {
      showModalTimer = setTimeout(openModal, PROMO_APPREAR_DELAY);
    }

    return () => clearTimeout(showModalTimer);
  }, []);

  useEffect(() => {
    isVisibleModal &&
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  }, [documentElement, isVisibleModal]);

  const onCloseModal = useCallback(() => {
    closeModal();
    if (isBrowser() && !getCookie(PROMO_COOKIE_NAME)) {
      document.cookie = `${PROMO_COOKIE_NAME}=set; expires=${new Date(
        dayjs().add(promoModalCountdown, 'hour').format()
      ).toUTCString()}; path=/`;
    }
  }, []);

  const onClickPromoButton = useCallback(() => {
    if (!isBrowser()) return;
    window.open(promoModalPdfLink.gatsbyDocFile.relativePath);
  }, [promoModalPdfLink]);

  useClickOutside(modalRef, onCloseModal, ['covid-banner']);

  return isVisibleModal ? (
    <Modal
      className="modal-with-promo-banner"
      isVisible={isVisibleModal}
      onClose={onCloseModal}
      closeIconColor="#E31E2F"
      closeBtnAriaLabel={promoModalCloseButtonAriaLabel}
      wrapperRef={modalRef}
    >
      <div className="promo-banner">
        <div className="promo-banner__column-content">
          <UniversalImage
            img={promoModalImage}
            imageAlt={promoModalImageAlt}
            imgStyle={{ objectFit: 'contain' }}
          />
          <h5 className="promo-banner__title">{promoModalTitle}</h5>
          <DangerouslySetInnerHtml
            className="promo-banner__description"
            html={promoModalDescription}
          />
          <div className="promo-banner__download-link-wrapper">
            <NFButton
              onClick={onClickPromoButton}
              btnText={promoModalCTAText}
              ariaLabel={promoModalPdfName}
              type="button"
              size="md"
              className="promo-banner__download-link"
            />
          </div>
        </div>

        <div className="promo-banner__bottom-content">
          <DangerouslySetInnerHtml
            className="promo-banner__disclaimer"
            html={promoModalBottomDisclaimer}
          />
        </div>
      </div>
    </Modal>
  ) : null;
};

export default PromoModal;
