import { useState } from 'react';
import { IUseModalReturnType } from './model';

const useModal = (initialValue: boolean): IUseModalReturnType => {
  const [isVisible, setIsVisible] = useState<boolean>(initialValue);
  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  return {
    isVisibleModal: isVisible,
    openModal,
    closeModal,
  };
};

export default useModal;
