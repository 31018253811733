import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import pageExtractor from './extractors/page';
import { IPageSchema } from './models';

const PageSchema: FC<IPageSchema> = ({ siteSettings, brandSettings, type, name, path }) => {
  const { siteUrl, siteName, lang } = siteSettings;
  const pageHref = path ? `${siteUrl}${path}` : siteUrl;

  const brandInformation = {
    ...brandSettings,
    lang,
    siteName,
    siteUrl,
    sameAs: brandSettings.socialNetworks.map((item) => item.value),
  };

  const jsonLd = {
    name: name || siteName,
    '@context': 'http://schema.org',
    '@type': type,
    ...pageExtractor(pageHref, brandInformation),
  };

  const jsonLdSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(jsonLd),
  };

  return <Helmet script={[jsonLdSchema]} />;
};

export default PageSchema;
