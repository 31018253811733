import React, { FC } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { BottomFooterProps } from './model';

import './BottomFooter.scss';

const currentYear = new Date().getFullYear();

const BottomFooter: FC<BottomFooterProps> = ({ copyrightInfo }) => {
  return (
    <Row>
      <Col>
        <div
          className="nf-footer-bottom"
          dangerouslySetInnerHTML={{
            __html: copyrightInfo.replace('currentYear', `${currentYear}`),
          }}
        />
      </Col>
    </Row>
  );
};
export default BottomFooter;
