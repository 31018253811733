import React, { FC } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import classNames from 'classnames';

import { IGatsbyImageComponentProps } from 'components/common/GatsbyImage/models';

const GatsbyImage: FC<IGatsbyImageComponentProps> = ({
  isLazyLoading,
  alt,
  classes,
  fluid,
  fixed,
  objectFitData,
  focalPoint,
  withDirectionClassName,
  ...restProps
}) => {
  const verticalPoint = focalPoint?.y || 50;
  const horizontalPoint = focalPoint?.x || 50;

  const classList = classNames(classes, {
    'vertical-direction': withDirectionClassName && fluid && fluid.aspectRatio < 1,
    'horizontal-direction': withDirectionClassName && fluid && fluid.aspectRatio >= 1,
    'quadrant-horizontal-orientation':
      withDirectionClassName && fluid && fluid.aspectRatio >= 1 && fluid.aspectRatio < 1.5,
    'quadrant-vertical-orientation':
      withDirectionClassName && fluid && fluid.aspectRatio < 1 && fluid.aspectRatio > 0.7,
  });

  return (
    <Img
      fadeIn={isLazyLoading}
      loading={isLazyLoading ? 'lazy' : 'eager'}
      fluid={fluid}
      {...(fixed ? { fixed } : {})}
      alt={alt || ''}
      style={{ height: '100%' }}
      objectFit={objectFitData?.objectFit || 'cover'}
      objectPosition={
        objectFitData?.objectPosition
          ? objectFitData.objectPosition
          : `${horizontalPoint}% ${verticalPoint}%`
      }
      className={classList}
      {...restProps}
    />
  );
};

GatsbyImage.defaultProps = {
  isLazyLoading: true,
};

export default GatsbyImage;
