import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import useClickOutside from 'hooks/useClickOutside';
import './HeaderSearch.scss';

interface IPropsHeaderSearch {
  searchButtonText: string;
  searchInputPlaceholder: string;
  isSearchFieldVisible: boolean;
  handleSearchFieldVisibility: () => void;
  searchPath?: string;
}

const HeaderSearch: FC<IPropsHeaderSearch> = (props): ReactElement | null => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isPlaceholderVisible, setPlaceholderVisible] = useState<boolean>(true);
  const wrapperRef = useRef(null);

  const {
    isSearchFieldVisible,
    handleSearchFieldVisibility,
    searchButtonText,
    searchInputPlaceholder,
    searchPath,
  } = props;

  const handleCloseSearchField = useCallback(() => {
    if (!isSearchFieldVisible) {
      return;
    }
    handleSearchFieldVisibility();
  }, [isSearchFieldVisible]);

  useClickOutside(wrapperRef, handleCloseSearchField, ['header-search-toggler']);

  const handleRedirect = useCallback(() => {
    if (!searchValue) {
      return;
    }

    navigate(`${searchPath || '/search'}?query=${searchValue}`);
  }, [searchValue]);

  const handlePlaceholderVisibility = useCallback(
    (value: boolean) => () => {
      setPlaceholderVisible(value);
    },
    []
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      handleRedirect();
    },
    [handleRedirect]
  );

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }, []);

  const handleClearSearchValue = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, []);

  return isSearchFieldVisible ? (
    <div className="hf-header-search" ref={wrapperRef}>
      <Form className="form" onSubmit={handleSubmit}>
        <div className="search-input-holder">
          <button className="return-btn" type="button" onClick={handleSearchFieldVisibility}>
            <img
              src="https://phx-nurofen-br-uat.husky-2.rbcloud.io/media/oiubxo5h/return_icon.svg?width=500&mode=max&animationprocessmode=first"
              alt="return icon"
            />
          </button>
          <FormControl
            type="text"
            placeholder={isPlaceholderVisible ? searchInputPlaceholder : ''}
            className="search-input"
            value={searchValue}
            onFocus={handlePlaceholderVisibility(false)}
            onBlur={handlePlaceholderVisibility(true)}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
          <div className="close-btn-holder">
            <button className="close-btn" type="button" onClick={handleClearSearchValue}>
              <img
                src="https://phx-nurofen-br-uat.husky-2.rbcloud.io/media/h3blf5hg/1544641784.svg?width=500&mode=max&animationprocessmode=first"
                alt="close icon"
              />
            </button>
          </div>
        </div>
        <button type="button" className="search-btn" onClick={handleRedirect}>
          {searchButtonText}
        </button>
      </Form>
    </div>
  ) : null;
};

export default HeaderSearch;
