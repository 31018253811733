import React from 'react';
import ELEMENTS, { COVID_BANNER_TYPE, COVID_BANNER_POSITION } from 'utils/constants';

export const wrapperInlineStylesForCovidBanner = ({
  covidBanner,
  isShownBanner,
}): React.CSSProperties => {
  let paddingTop;
  let paddingBottom;
  if (covidBanner && isShownBanner) {
    if (
      covidBanner.type === COVID_BANNER_TYPE.sticky &&
      covidBanner.position === COVID_BANNER_POSITION.bottom
    ) {
      paddingBottom = `${document.getElementById(ELEMENTS.COVID_BANNER_ID)?.clientHeight}px`;
    }
    if (
      covidBanner.type === COVID_BANNER_TYPE.sticky &&
      covidBanner.position === COVID_BANNER_POSITION.top
    ) {
      paddingTop = `${document.getElementById(ELEMENTS.COVID_BANNER_ID)?.clientHeight}px`;
    }
    if (
      covidBanner.type === COVID_BANNER_TYPE.pinned &&
      covidBanner.position === COVID_BANNER_POSITION.top
    ) {
      paddingTop = '1px';
    }
  }

  return {
    paddingTop,
    paddingBottom,
  };
};

export const headerInlineStylesForCovidBanner = ({
  covidBanner,
  isShownBanner,
}): React.CSSProperties => {
  let top;
  if (covidBanner && isShownBanner) {
    if (
      covidBanner.type === COVID_BANNER_TYPE.sticky &&
      covidBanner.position === COVID_BANNER_POSITION.top
    ) {
      top = `${document.getElementById(ELEMENTS.COVID_BANNER_ID)?.clientHeight}px`;
    }
  }

  return {
    top,
  };
};
