import { useState, useEffect } from 'react';
import useScreenRecognition from './useScreenRecognition';

const useOnDetectOrientation = () => {
  const { windowWidth, windowHeight } = useScreenRecognition();
  const [isPortraitOrientation, setPortraitOrientation] = useState<boolean | null>(null);

  useEffect(() => {
    if (!windowWidth || !windowHeight) return;

    setPortraitOrientation(windowWidth < windowHeight);
  }, [windowWidth, windowHeight]);

  return { isPortraitOrientation };
};

export default useOnDetectOrientation;
