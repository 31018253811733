import React, { FC, useEffect, useState } from 'react';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';
import './LegalPopup.scss';

const LegalPopup: FC<LegalPopupTypes.ILegalPopup> = ({
  legalPopupTitle,
  legalPopupDescription,
  legalPopupButton,
  legalPopupButtonAria,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    sessionStorage.setItem('legalPopup', 'closed');
  };

  useEffect(() => {
    if (!sessionStorage.getItem('legalPopup')) {
      setIsOpen(true);
    }
  }, []);

  return isOpen ? (
    <div className="legalPopup">
      <div className="legalPopup__popup">
        <h2 className="legalPopup__title">{legalPopupTitle}</h2>
        <DangerouslySetInnerHtml className="legalPopup__content" html={legalPopupDescription} />
        <div className="legalPopup__actions">
          <NFButton btnText={legalPopupButton} ariaLabel={legalPopupButtonAria} onClick={onClose} />
        </div>
      </div>
    </div>
  ) : null;
};

export default LegalPopup;
