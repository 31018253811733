import { useState, useEffect } from 'react';
import { isBrowser } from 'utils/browser';

const useLocalStorage = (key: string): [any, Function] => {
  const storedValue = isBrowser() && window.localStorage.getItem(key);
  const initialValue = !storedValue ? null : JSON.parse(storedValue);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
