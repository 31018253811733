import React, { FC, useEffect, useState, useCallback } from 'react';

import { IDisclaimerComponentProps } from 'components/Disclaimer/model';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';

import 'components/Disclaimer/Disclaimer.scss';

const DISCLAIMER_DURATION_APPEARANCE = 350;

const Disclaimer: FC<IDisclaimerComponentProps> = ({
  label,
  popupTitle,
  popupText,
  popupCloseButtonText,
  popupCloseButtonAriaLabel,
}) => {
  const [documentElement, setDocumentElement] = useState<HTMLElement>();
  useEffect(() => {
    setDocumentElement(document.body);
  }, []);

  const onToggleDisclaimer = useCallback(() => {
    if (!documentElement) return;

    if (!documentElement.classList.contains('offcanvas-disclaimer-overlay')) {
      documentElement.classList.add('offcanvas-disclaimer-overlay');
    } else {
      documentElement.classList.remove('offcanvas-disclaimer-overlay');
    }

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, DISCLAIMER_DURATION_APPEARANCE + 70);
  }, [documentElement]);

  return (
    <>
      <section className="offcanvas-disclaimer__popup">
        <button
          onClick={onToggleDisclaimer}
          className="offcanvas-disclaimer__label"
          type="button"
          aria-label={label}
        >
          <span>{label}</span>
        </button>

        <h5 className="offcanvas-disclaimer__headline">{popupTitle}</h5>
        <DangerouslySetInnerHtml
          className="offcanvas-disclaimer__bodytext"
          element="div"
          html={popupText}
        />
        <button
          onClick={onToggleDisclaimer}
          className="offcanvas-disclaimer__close-btn"
          type="button"
          aria-label={popupCloseButtonAriaLabel}
        >
          <IconCustom icon="close_icon-2" />
          <span className="offcanvas-disclaimer__close-btn-text">{popupCloseButtonText}</span>
        </button>
      </section>
    </>
  );
};

export default Disclaimer;
