import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import { IButtonProps } from './model';

import './Button.scss';

const Button: FC<IButtonProps> = ({
  ariaLabel,
  children,
  link,
  variant,
  className,
  clickHandler,
  type = 'button',
  target,
  textAlign,
  disabled,
  onKeyDown,
}) => {
  const classesButton = classNames(
    `button`,
    {
      [`button--${variant}`]: variant,
      [`text-align-${textAlign}`]: textAlign,
    },
    className
  );

  if (link) {
    const isInternal = /^\/(?!\/)/.test(link);

    return (
      <>
        {isInternal ? (
          <Link
            data-test="Button"
            rel="preload"
            className={classesButton}
            aria-label={ariaLabel}
            to={link}
          >
            {children}
          </Link>
        ) : (
          <a
            data-test="Button"
            className={classesButton}
            aria-label={ariaLabel}
            href={link}
            rel="noopener noreferrer"
            onKeyDown={onKeyDown}
            {...(target && { target })}
          >
            {children}
          </a>
        )}
      </>
    );
  }

  return (
    <button
      aria-label={ariaLabel}
      data-test="Button"
      onClick={clickHandler}
      className={classesButton}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const query = graphql`
  fragment FragmentButton on TButtonStructure {
    properties {
      ariaLabel
      label
      link {
        url
        name
        target
        queryString
      }
    }
  }
`;

export default Button;
