import React, { FC, ReactElement, RefObject, useRef } from 'react';
import { graphql } from 'gatsby';

import useClickOutside from 'hooks/useClickOutside';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import NFButton from 'components/common/NFButton';
import { parseNFButton } from 'components/common/NFButton/parsers';
import Modal from 'components/Modal';

import './RedirectionModal.scss';
import { IPropsRedirectionModal } from './model';

const RedirectionModal: FC<IPropsRedirectionModal> = ({
  isVisible,
  handleClose,
  closeBtnTitle,
  message,
  messageIcon,
  messageIconAlt,
  redirectCTA,
  stayCTA,
}): ReactElement => {
  const wrapperRef: RefObject<HTMLDivElement> = useRef(null);
  useClickOutside(wrapperRef, handleClose);

  return (
    <Modal
      className="redirection-modal"
      isVisible={isVisible}
      onClose={handleClose}
      closeIconColor="navy"
      closeBtnAriaLabel="test"
      closeBtnTitle={closeBtnTitle}
      wrapperRef={wrapperRef}
    >
      <div className="redirection-modal-content">
        <div className="redirection-modal-message">
          <UniversalImage
            imageAlt={messageIconAlt}
            img={messageIcon}
            wrapperClassName="redirection-modal-message-icon"
          />
          <DangerouslySetInnerHtml html={message} className="redirection-modal-message-text" />
        </div>

        <div className="cta-holder">
          {redirectCTA ? <NFButton {...parseNFButton(redirectCTA)} target="_blank" /> : null}
          {stayCTA ? <NFButton {...parseNFButton(stayCTA)} onClick={handleClose} /> : null}
        </div>
      </div>
    </Modal>
  );
};

export const query = graphql`
  fragment FragmentRedirectionModal on TSocialRedirectionModalsProperties {
    properties {
      socialRedirectionModalMediaLinkTarget
      socialRedirectionModalCloseTitle
      socialRedirectionModalMessage
      socialRedirectionModalMessageIcon {
        fallbackUrl
        svg {
          content
        }
      }
      socialRedirectionModalRedirectCTA {
        properties {
          ...FragmentNFButton
        }
      }
      socialRedirectionModalStayCTA {
        properties {
          ...FragmentNFButton
        }
      }
      socialRedirectionModalCloseAriaLabel
    }
  }
`;

export default RedirectionModal;
