import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml/DangerouslySetInnerHtml';
import useModal from 'hooks/useModal';

import { IHCPPopupProps } from './model';
import { HCP_ACCEPTED, HCP_SHOW_DELAY } from './contants';

import './HCPPopup.scss';

const HCPPopup: FC<IHCPPopupProps> = ({
  title,
  description,
  confirmButtonText,
  rejectButtonText,
  confirmButtonTextAriaLabel,
  rejectButtonTextAriaLabel,
  rejectRedirectUrl,
}) => {
  const [documentBody, setDocumentBody] = useState<HTMLElement>();
  const { isVisibleModal, openModal, closeModal } = useModal(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sessionStorage.getItem(HCP_ACCEPTED)) return;
    setDocumentBody(document.body);
    setTimeout(() => {
      openModal();
    }, HCP_SHOW_DELAY);
  }, []);

  useEffect(() => {
    if (documentBody) {
      documentBody.style.overflow = isVisibleModal ? 'hidden' : 'initial';
    }

    if (isVisibleModal) {
      modalRef.current?.focus();
    }
  }, [documentBody, isVisibleModal]);

  const handleHCPAcceptedClick = useCallback(() => {
    sessionStorage.setItem(HCP_ACCEPTED, 'true');
    closeModal();
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isVisibleModal && e.key === 'Tab') {
        const focusableElements = modalRef.current?.querySelectorAll('button, a');
        const firstElement = focusableElements?.[0] as HTMLButtonElement;
        const lastElement = focusableElements?.[focusableElements?.length - 1] as HTMLAnchorElement;
        const { activeElement } = document;

        if (e.shiftKey && activeElement === firstElement) {
          e.preventDefault();
          lastElement?.focus();
        } else if (!e.shiftKey && activeElement === lastElement) {
          e.preventDefault();
          firstElement?.focus();
        }
      }
    },
    [isVisibleModal]
  );

  const rejectUrl = rejectRedirectUrl?.[0]?.url;
  const handleAnchorKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
      window.location.href = rejectUrl;
    }
  }, []);

  return isVisibleModal && documentBody
    ? ReactDOM.createPortal(
        <div className="modal-overlay" data-testid="hcp-modal">
          <div
            role="button"
            ref={modalRef}
            tabIndex={-1}
            onKeyDown={handleKeyDown}
            className="modal"
          >
            <DangerouslySetInnerHtml className="modal__title" html={title} />
            <DangerouslySetInnerHtml className="modal__description" html={description} />
            <div className="modal__controls">
              <button
                type="button"
                className="modal__btn modal__btn--confirm"
                aria-label={confirmButtonTextAriaLabel}
                onClick={handleHCPAcceptedClick}
              >
                {confirmButtonText}
              </button>
              {rejectUrl ? (
                <Button
                  link={rejectUrl}
                  ariaLabel={rejectButtonTextAriaLabel}
                  variant="reject"
                  textAlign="center"
                  onKeyDown={handleAnchorKeyDown}
                >
                  {rejectButtonText}
                </Button>
              ) : null}
            </div>
          </div>
        </div>,
        documentBody
      )
    : null;
};

export default HCPPopup;
