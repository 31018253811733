import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import useScreenRecognition from 'hooks/useScreenRecognition';
import GatsbyImage from 'components/common/GatsbyImage';
import ImageUsual from 'components/common/UniversalImage/ImageUsual';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPropsUniversalImage } from './model';
import './UniversalImage.scss';
import getImageSrc from './helpers';

const UniversalImage: FC<IPropsUniversalImage> = ({
  img,
  imageAlt,
  isLazyLoading,
  wrapperClassName,
  imageClassName,
  imageStyle,
  objectFitData,
  ...restProps
}): React.ReactElement | null => {
  const { isMobile } = useScreenRecognition();

  const wrapperClasses = classNames('universal-image', {
    [wrapperClassName || '']: wrapperClassName,
  });
  const src = useMemo(() => getImageSrc(img), [img]);
  let imgSharp;

  if (img && typeof img !== 'string') {
    imgSharp = isMobile ? (img?.mobileImage ? img.mobileImage : img.gatsbyImage) : img.gatsbyImage;
  }

  return !img ? null : (
    <div data-test="UniversalImage" className={wrapperClasses}>
      {typeof img !== 'string' && img?.svg?.content ? (
        <DangerouslySetInnerHtml element="div" html={img.svg.content} />
      ) : null}

      {typeof img !== 'string' ? (
        imgSharp?.childImageSharp?.fluid || imgSharp?.childImageSharp?.fixed ? (
          <GatsbyImage
            fluid={imgSharp.childImageSharp?.fluid}
            fixed={imgSharp.childImageSharp?.fixed}
            alt={imageAlt}
            isLazyLoading={isLazyLoading}
            objectFitData={objectFitData}
            focalPoint={img?.focalPoint}
            {...restProps}
          />
        ) : (
          <ImageUsual
            src={src}
            imageAlt={imageAlt}
            imageClassName={imageClassName}
            imageStyle={imageStyle}
          />
        )
      ) : (
        <ImageUsual
          src={src}
          imageAlt={imageAlt}
          imageClassName={imageClassName}
          imageStyle={imageStyle}
        />
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentFluidSmallImage on TGatsbyImageProperties {
    fallbackUrl
    gatsbyImage {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }

  fragment FragmentFixedSmallImage on TGatsbyImageProperties {
    fallbackUrl
    gatsbyImage {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }

  fragment FragmentFluidMiddleImage on TGatsbyImageProperties {
    fallbackUrl
    gatsbyImage {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default UniversalImage;
