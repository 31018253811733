const getImageSrc = (img: AppImages.IUniversalImageData | string | undefined): string => {
  if (!img) return '';
  if (typeof img === 'string') {
    return img;
  }
  if (typeof img !== 'string' && img.svg?.content) {
    return '';
  }
  if (typeof img !== 'string' && !img?.gatsbyImage?.childImageSharp) {
    return img.fallbackUrl;
  }

  return '';
};

export default getImageSrc;
