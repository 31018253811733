import React, { FC, useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import HeaderCustomNavigation from 'components/HeaderCustom/HeaderCustomNavigation';
import NFButton from 'components/common/NFButton';
import HeaderLogo from 'components/Header/HeaderLogo';

import 'components/HeaderCustom/HeaderCustom.scss';
import 'components/Header/Header.scss';

const HeaderCustom: FC<HeaderTypes.IPropsHeaderCustom> = ({
  headerLogo,
  headerLogoAriaLabel,
  headerLogoLink,
  navItems,
  buyNowBtn,
  mediaLinks,
  hideSocialMediaBlock,
  headerMobileOpener,
  sectionNavLandmark,
}) => {
  const [isOpen, setValue] = useState(false);

  const handleNavMenuVisibility = useCallback(() => {
    setValue((oldValue) => !oldValue);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
  }, [isOpen]);

  return (
    <header className={classNames('header-custom', { 'nav-active': isOpen })} id="header-custom">
      <Container fluid className="header-container">
        <HeaderLogo
          logo={headerLogo}
          headerLogoAriaLabel={headerLogoAriaLabel}
          headerLogoLink={headerLogoLink}
        />
        <div className="header-navigation-holder">
          <NFButton
            btnText={buyNowBtn?.[0]?.name || ''}
            link={buyNowBtn?.[0]?.url}
            target={buyNowBtn?.[0]?.target}
          />
          <button type="button" className="nav-opener" onClick={handleNavMenuVisibility}>
            <span className="accessibility-hidden">{headerMobileOpener}</span>
          </button>
          <HeaderCustomNavigation
            navItems={navItems}
            mediaLinks={mediaLinks}
            hideSocialMediaBlock={hideSocialMediaBlock}
            ariaLabel={sectionNavLandmark}
          />
        </div>
      </Container>
    </header>
  );
};

export const query = graphql`
  fragment FragmentHeaderCustom on IHeaderCustom {
    headerLogo {
      fallbackUrl
      svg {
        content
      }
    }
    navItems {
      properties {
        simpleLink {
          name
          url
          queryString
          target
        }
      }
    }
    buyNowBtn {
      url
      name
      target
    }
    hideSocialMediaBlock
    headerMobileOpener
  }
`;

export default HeaderCustom;
